import { allFields, getFieldValues } from 'components/application/Form/useFieldValues';
import React from 'react';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import { isEmpty } from 'utils/helpers';

// This maps the value and onChange events from MUI inputs to the form element
export const ConnectFormField = ({ id, Field = undefined, ...other }) => {
  const { watch, setValue } = useFormContext();
  const value = watch(id);
  const onChange = React.useCallback(
    e => {
      const value = e?.target?.value;
      setValue(id, value);
    },
    [id, setValue]
  );

  return Field === null || Field === undefined ? (
    <></>
  ) : typeof Field === 'function' ? (
    <Field id={id} {...other} value={value} onChange={onChange} />
  ) : (
    React.cloneElement(Field, { id, ...other, value, onChange })
  );
};

export default function ReactFormProvider({ children }) {
  const methods = useForm();
  const { register, handleSubmit, getValues } = methods;
  const onSubmit = data => console.log('form submit...', data);
  const values = getFieldValues(getValues);
  console.log({ values });
  return (
    <>
      <FormProvider {...methods}>
        <form style={{ display: 'none' }} onSubmit={handleSubmit(onSubmit)}>
          <input id='leadid_token' type='hidden' name='universal_leadid' {...register('leadid_token')} />
          {allFields
            .filter(f => f.skip !== true && f.exclude !== true)
            .map(({ id, value, type, defaultValue, formLabel }, i) =>
              !isEmpty(formLabel) ? (
                id === 'leadid_tcpa_disclosure' ? (
                  <label key={`${id}-${i}`}>
                    <input type={type} id={id} {...register(id)} name={undefined} />
                    {formLabel}
                  </label>
                ) : (
                  <label key={`${id}-${i}`}>
                    <input id={id} type={type} defaultValue={defaultValue} value={values[id]} {...register(id)} />
                    {formLabel}
                  </label>
                )
              ) : (
                <input key={`${id}-${i}`} id={id} type={type} defaultValue={defaultValue} value={values[id]} {...register(id)} />
              )
            )}
          <input type='submit' />
        </form>
        {children}
      </FormProvider>
    </>
  );
}
