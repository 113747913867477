import React from 'react';
import Snackbar from '../application/Snackbar/Snackbar';
import ApplicationBar from './ApplicationBar/ApplicationBar';
import ScrollElementProvider from './ScrollElementProvider';
import { Box, Container } from '@mui/material';
import { useRouter } from 'next/router';
import ToolbarPlaceholder from './ToolbarPlaceholder';
import { Footer } from './Footer';
import useUrlParameterFields from 'components/application/Fields/useUrlParameterFields';
import { useAutoPhoneNumber } from 'store/layout/store';
import useGeoData from 'utils/useGeoData';
import { grey } from '@mui/material/colors';

const noScrollPaths = [];
export default function Layout({ children }) {
  var [scrollElement, setScrollElement] = React.useState(null);
  const { pathname } = useRouter();
  const noScroll = noScrollPaths.find(p => p === pathname) ? true : false;
  useUrlParameterFields(); // Must be here to catch initial url parameters for home route
  useGeoData({ trigger: true });

  // Disable phone api for now until it is updated
   useAutoPhoneNumber(); // trigger phone number logic

  return (
    <Box data-testid='app-container' sx={{ display: 'static' }}>
      <main
        id='app-content'
        ref={setScrollElement as any}
        style={{
          flexGrow: 1,
          overflow: noScroll ? 'hidden' : 'auto',
          overflowX: 'hidden',
          height: '100vh',
          backgroundColor: 'white'
        }}
      >
        <ScrollElementProvider value={scrollElement}>
          <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Container maxWidth='xl'>
              <ApplicationBar />
              <ToolbarPlaceholder />
              <div style={{ flex: 1, overflow: 'hidden', paddingBottom: 16, background: grey[100] }}>{children}</div>
              <Snackbar />
              <Footer />
            </Container>
          </Box>
        </ScrollElementProvider>
      </main>
    </Box>
  );
}
