import React from 'react';
import { Reducer } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { defaultGeoState } from 'utils/useGeoData';
import { isClient, isEmpty } from 'utils/helpers';
import axios from 'axios';
import pkg from 'package.json';
import getUrlParameter from 'utils/getUrlParameter';
import { useFormContext } from 'react-hook-form';

export interface PhoneInfo {
  time: number;
  phone_num: string;
  expiration_time: string;
}

export interface State {
  routeState: any;
  geoData: any;
  geo: any;
  phoneInfo?: PhoneInfo;
  isZipSet?: boolean; // Flag for indicating if zip code has been loaded
}

const defaultState = {
  routeState: {},
  geoData: defaultGeoState,
  phoneInfo: {
    phone_num: undefined,
    expiration_time: undefined,
    time: undefined
  },
  isZipSet: false
};

export const reducer: Reducer<State> = (state: State | any, action) => {
  switch (action.type) {
    case 'ROUTE_STATE':
      return {
        ...state,
        routeState: action.state
      };
    case 'UPDATE_LAYOUT':
      return {
        ...state,
        ...action.payload
      };
    case 'PHONE_INFO':
      return {
        ...state,
        phoneInfo: action.phoneInfo
      };
    default:
  }
  return state || { ...defaultState };
};

export const useRouteState = () => {
  const dispatch = useDispatch();
  const setState = React.useCallback(state => dispatch({ type: 'ROUTE_STATE', state }), [dispatch]);
  const state = useSelector((state: AppState) => state.layout.routeState);
  return [state, setState];
};

export const useUpdateLayout = () => {
  const dispatch = useDispatch();
  return React.useCallback(
    payload => {
      dispatch({ type: 'UPDATE_LAYOUT', payload });
    },
    [dispatch]
  );
};

export const usePhoneInfo = () => {
  const dispatch = useDispatch();
  const setPhoneInfo = React.useCallback(phoneInfo => dispatch({ type: 'PHONE_INFO', phoneInfo }), [dispatch]);
  const phoneInfo = useSelector((state: AppState) => state.layout.phoneInfo);
  return { phoneInfo, setPhoneInfo };
};

export const getSharedPramaters = () => {
  const sub_1 = getUrlParameter('sub1');
  const sub_2 = getUrlParameter('sub2');
  const sub_3 = !isClient ? undefined : (window as any)?.EF?.getAdvertiserTransactionId(1);
  return { sub_1, sub_2, sub_3 };
};

export const useAutoPhoneNumber = () => {
  const { phoneInfo, setPhoneInfo } = usePhoneInfo();
  const IPv4 = useSelector((s: AppState) => s.layout.geoData.IPv4);
  const lp_request_id = getUrlParameter('lp_request_id');

  React.useEffect(() => {
    console.log('Use effect', { lp_request_id, IPv4 });

    const getPhoneInfo = async () => {
      console.log('getting phone info', {});
      try {
        //var result = await requestPhoneNumber({
        //body: {
        //            ip_address: !isEmpty(IPv4) ? IPv4 : '1.2.3.4',
        //          lp_request_id
        //      }
        //  });
        // Step 2 - Build the Options JSON Object For the Default Numbers to use and the additional Tokens to Add/Override if found in the QueryString

        // Declare variables for cookies so we can use them on the Tokens Object
        var options = {
          offer_token: '5390642cf7ae8128d822e692ba614d63',
          default_number: {
            human_number: '(833) 522-4328',
            plain_number: '8335224328'
          },
          tokens: {
            source_url: 'https://autosaver.org',
            gtm_container_id: 'GTM-WNT27ZZB'
            //source_url: '{{Page Hostname}}{{Page Path}}',
            //gtm_container_id: '{{Container ID}}'
          }
        };

        // Step 3 - Call TrackDrive to Assign a Number using the Options and the Search and Replace Array
        const numbers = [
          ['(833) 522-4328', 'human_number'],
          ['(833)5224328', 'plain_number'],
          ['8335224328', 'plain_number']
        ];

        console.log('Calling track drive replace all', { options, numbers });
        (window as any).Trackdrive.Optimizer.replace_all(options, numbers);
        console.log('Done calling track drive');
        //console.log('Received phone number', result);
        //setPhoneInfo(result);
      } catch (err) {
        console.error('Error requesting phone number', err);
      }
    };
    const runUntilAvailable = () => {
      if ((window as any)?.Trackdrive) {
        console.log('Track drive is available!!!');
        getPhoneInfo();
      } else {
        console.log('Track drive is not available yet...waiting 2 seconds');
        setTimeout(() => runUntilAvailable(), 2000);
      }
    };
    runUntilAvailable();

    return () => {};
    // eslint-disable-next-line
  }, [lp_request_id, IPv4]);

  return { phoneInfo };
};

// Other required fields are merged in the server api code
export async function requestPhoneNumber({ body }) {
  try {
    console.log('Requesting phone number...', body);
    const result = await axios.post(`/api/phone_number`, body);
    return result.data;
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw err;
  }
}

export async function validateAddress({ addressLines }) {
  try {
    console.log('Validating address...', addressLines);
    const result = await axios.post(`/api/validate_address`, {
      address: {
        addressLines
      }
    });
    return result.data;
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw err;
  }
}

export const useCurrentPhone = () => {
  const { phoneInfo } = usePhoneInfo() as any;
  //const AffID2 = '18332003107';
  //const AffID1 = '18553320737';

  //const affiliate_id = getUrlParameter('affiliate_id') as any;
  //const { watch } = useFormContext();
  //const annualIncome = watch('Annual_Income');

  const phone_num = !isEmpty(phoneInfo?.number) ? phoneInfo.number : !isEmpty(phoneInfo?.display) ? phoneInfo.display : pkg.currentPhone;
  var string_phone_number = typeof phone_num === 'number' ? phone_num.toString() : phone_num;
  /*if (annualIncome === 'More than $50,000') {
    // override phone number if user answers > 50k for annual income
    string_phone_number =
      affiliate_id === 1 || affiliate_id === '1'
        ? AffID1
        : affiliate_id === 2 || affiliate_id === '2'
        ? AffID2
        : affiliate_id === undefined
        ? AffID1
        : string_phone_number;
  }*/
  //console.log({ version: pkg.version, annualIncome, affiliate_id, type: typeof phone_num, phoneInfo, string_phone_number });
  console.log({ version: pkg.version, type: typeof phone_num, phoneInfo, string_phone_number });
  var returnVal = string_phone_number;
  if (!isEmpty(string_phone_number)) {
    var stripped = (string_phone_number ?? '')?.replace(/[^0-9]/g, '');
    if (stripped.length === 11) {
      returnVal = stripped.slice(0, 1) + '-' + stripped.slice(1, 4) + '-' + stripped.slice(4, 7) + '-' + stripped.slice(7);
    }
  }
  return !isEmpty(returnVal) ? returnVal : pkg.currentPhone;
};
