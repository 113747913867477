import Router, { useRouter } from 'next/router';
import nProgress from 'nprogress';
import ReactGA from 'react-ga';
import React from 'react';
import Head from 'next/head';
import { AppProps } from 'next/app';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider, EmotionCache } from '@emotion/react';
import theme from '../styles/theme';
import createEmotionCache from '../styles/createEmotionCache';
import { Provider } from 'react-redux';
import store, { isPersistorEnabled } from '../store/store';
import Layout from '../components/layout/Layout';
import type { NextPage } from 'next';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import ReactFormProvider from 'components/layout/ReactFormProvider';
import Script from 'next/script';
import { isClient, isDev, isEmpty } from 'utils/helpers';
import pkg from 'package.json';
import { initSentryClient } from 'sentry.client.config';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const { googleAnalyticsKey } = pkg;

Router.events.on('routeChangeStart', nProgress.start);
Router.events.on('routeChangeError', nProgress.done);
Router.events.on('routeChangeComplete', nProgress.done);

// For google analytics

isClient && !isEmpty(googleAnalyticsKey) && ReactGA.initialize(googleAnalyticsKey, { debug: false }); // Initialize GA with your tracking ids
isClient && !isEmpty(googleAnalyticsKey) && ReactGA.plugin.require('ec');
isClient && initSentryClient();

type EnhancedAppProps = AppProps & {
  Component: NextPage;
  emotionCache: EmotionCache;
};

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

const persistor = isPersistorEnabled && persistStore(store); //Setup the global persistor

const PersistEnabledGate = ({ children }) =>
  isPersistorEnabled ? (
    <PersistGate loading={null} persistor={persistor}>
      {children}
    </PersistGate>
  ) : (
    children
  );

export default function MyApp(props: EnhancedAppProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const router = useRouter();

  React.useEffect(() => {
    const handleRouteChange = (url, { shallow }) => {
      console.log(`App is changing to ${url} ${shallow ? 'with' : 'without'} shallow routing`);
      ReactGA.send({ hitType: 'pageview', page: url, nonInteraction: true });
      ((window as any)?.uetq || []).push('event', 'page_view', { page_path: url });
    };
    if (!isEmpty(googleAnalyticsKey)) {
      router.events.on('routeChangeComplete', handleRouteChange);
    }
    return () => {
      if (!isEmpty(googleAnalyticsKey)) {
        router.events.off('routeChangeComplete', handleRouteChange);
      }
    };
    // eslint-disable-next-line
  }, []);

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <meta name='viewport' content='initial-scale=1, width=device-width' />
        <script type='text/javascript' src='//www.nextinsure.com/listingdisplay/loader/sh' async defer />
        <script src='//insurance.mediaalpha.com/js/serve.js' async defer />
        {/*<script src='//b-js.ringba.com/CA11613393ca6f429aabc331bcf9f73f2d' async defer></script>*/}
      </Head>
      <Script src='https://trackdrive.net/assets/trackdrive-optimizer.js'></Script>
      {!isDev() && (
        <Script
          id='gtm-script'
          strategy='beforeInteractive'
          dangerouslySetInnerHTML={{
            __html: `(function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
            var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', 'GTM-WNT27ZZB');`
          }}
        />
      )}
      {/*<Script type='text/javascript' src='/js/trustedForm.js' strategy='lazyOnload' defer />
      <noscript>
        <img src='https://api.trustedform.com/ns.gif' />
      </noscript>
      <Script id='LeadiDscript' type='text/javascript' src='/js/journaya.js' defer />
      <noscript>
        <img src='//create.leadid.com/noscript.gif?lac=2CB13E07-1461-5BE7-31EF-4A57F37B9B9A&lck=a1ae25b2-444e-6a9d-7859-40d86c4aa02b&snippet_version=2' />
      </noscript>
      */}
      {/*!isDev() && <Script type='text/javascript' src='/js/microsoftClarity.js' defer />*/}
      {/*!isDev() && <Script type='text/javascript' src='/js/trackDrive.js' defer />*/}
      <Provider store={store}>
        <PersistEnabledGate>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ReactFormProvider>
              <ThemeProvider theme={theme}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />

                <Layout>
                  <Component {...pageProps} />
                </Layout>
              </ThemeProvider>
            </ReactFormProvider>
          </LocalizationProvider>
        </PersistEnabledGate>
      </Provider>
    </CacheProvider>
  );
}
