import React from 'react';
import Text from 'components/application/Fields/Text';
import DateOfBirth, { defaultDob } from 'components/application/Fields/DateOfBirth';
import PhoneNumber from '../Fields/PhoneNumber';
import { validateEmail, validatePhone, validateZipCode } from '../GenericDialog/helpers';
import { arrayMove, arrayMoveNew, evalFunc, formatPhoneNumber, getAge, hostAddress, isClient, isDev, isEmpty } from 'utils/helpers';
import MultiButtons from 'components/application/Fields/MultiButtons';
import Grid from '@mui/material/Grid';
import Info from '@mui/icons-material/Info';
import { Chip } from '@mui/material';
import GeoCity from '../Fields/GeoCity';
import GeoZip from '../Fields/GeoZip';
import GeoState from '../Fields/GeoState';
import pkg from 'package.json';
import moment from 'moment';
import { validateAddress } from 'store/layout/store';
import GeoZipHidden from '../Fields/GeoZipHidden';

export const submitButtonText = 'Get Your Quote';
export const disclaimerText = `By pressing "${submitButtonText}", I am providing my express written consent to receive marketing emails, telephone calls, text messages, using an automatic telephone dialing system or an artificial or prerecorded voice and text messages from ${pkg.companyName} its affiliates, and/or any of our marketing partners regarding their products and services (including Medicare Advantage plans, Medicare Part D Prescription Drug Plans or Medicare Supplement Insurance Plans) at the email address and telephone number provided, including my wireless phone number. Telephone companies may impose additional charges on subscribers for messages. I understand consent is not required as a condition of purchasing any property, goods, or services. I also consent to electronic video monitoring and recordation of my activities on this Site. I may call ${pkg.currentPhone} to speak with someone about obtaining an insurance quote. I acknowledge that I may revoke my consent at any time by emailing “STOP” to optout@autosaver.org.`;

const WhyWeAsk = () => {
  const [open, setOpen] = React.useState(false);
  return (
    <Grid container spacing={2} sx={{ fontSize: 14, mb: 2 }} alignItems='center' justifyContent={open ? 'flex-start' : 'center'}>
      <Grid item>
        <Chip
          onClick={() => setOpen(!open)}
          deleteIcon={<Info color='secondary' sx={{ color: 'white !important' }} />}
          onDelete={() => null}
          sx={{ color: 'white', backgroundColor: 'primary.light', cursor: 'pointer' }}
          label='Why we ask?'
        />
      </Grid>
      {open && (
        <Grid item xs sx={{ lineHeight: 1.35, color: 'text.secondary', textAlign: 'justify' }}>
          Some carriers specialize in specific states and regions. Providing your address will help you get the best rates in your area.
        </Grid>
      )}
    </Grid>
  );
};

export const insuranceCarrierOptions = [
  'Allstate',
  'Dairyland',
  'Direct General',
  'Elephant',
  'Geico',
  'Liberty Mutual',
  'Mercury',
  'Progressive',
  'SafeAuto',
  'State Farm',
  'Travelers',
  'Other'
].map(v => ({ label: v, value: v }));

export const vehicleCountOptions = [
  { label: '1', value: '1' },
  { label: '2', value: '2' }
  //{ label: '3', value: '3' },
  //{ label: '4', value: '4' },
  //{ label: '5+', value: '5' }
];

export const ageGroupOptions = [
  //{ label: '<18', value: '17' },
  { label: '18-24', value: '20' },
  { label: '25-34', value: '30' },
  { label: '35-44', value: '40' },
  { label: '45-54', value: '50' },
  { label: '55-69', value: '60' },
  { label: '70+', value: '70' }
];

export const creditRatingOptions = [
  { label: 'Excellent (750+)', value: '750' },
  { label: 'Good (700-749)', value: '700' },
  { label: 'Fair (650-699)', value: '650' },
  { label: 'Bad (649 or less)', value: '600' }
];

export const recentIncidentOptions = [
  { label: '0', value: '0' },
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3+', value: '3' }
];

const validateVarChar = (value = '', length) => (value.length <= length ? undefined : `Must be < ${length} characters`);

export const getQuestions = ({ next = undefined, handleSubmit = undefined, version = 0 } = {}) => {
  const resultArray = [
    {
      id: 'gender',
      label: 'Are you insured?',
      showSkip: undefined,
      showNext: false,
      Fields: [
        { id: 'src', value: '694088', type: 'hidden' }, // To be provided
        { id: 'originwidgetinstanceid', value: 'origin widget instance id', type: 'hidden' }, // internal QS use only
        { id: 'inventorytype', value: 'generic-listing-thankyoupage' }, //if applicable, set to "thankyoupage", or "leavebehind"
        { id: 'originsessionid', value: 'origin session id' }, //internal QS use only
        { id: 'zipcode', value: '' },
        { id: 'statecode', value: '' },
        { id: 'var1', value: 'subid 1' },
        { id: 'var2', value: 'subid 2' },
        { id: 'var3', value: 'subid 3' },
        { id: 'activelicense', value: '1' }, // 0 or 1
        { id: 'trn_id', type: 'hidden', value: values => (!isClient ? undefined : (window as any)?.EF?.getAdvertiserTransactionId(1)) }, //urlParameter: '_ef_transaction_id' },
        { id: 'transaction_ID', type: 'hidden', value: values => (!isClient ? undefined : (window as any)?.EF?.getAdvertiserTransactionId(1)) }, //urlParameter: '_ef_transaction_id' },
        {
          id: 'leadid', // jornaya leadId
          type: 'hidden',
          value: () => ((typeof window !== 'undefined' && window.document.querySelector('input[id="leadid_token"]')) as any)?.value
        },

        //{ id: 'trn_id', value: 'transactionId' },
        //{ id: 'leadid', value: 'jornaya leadId' },
        //{ id: 'currentlyinsured', value: '0 or 1' },
        //{ id: 'age', value: 'applicant age' },
        //{ id: 'age', type: 'hidden', value: values => getAge(values['date_of_birth']) }, // Calculate this on submit, use the DOB and a decimal result
        //{ id: 'carrier', value: 'current insurance carrier' }, //	see appendix for list
        //{ id: 'vehiclecount', value: 'number of vehicles to be insured' },
        //{ id: 'creditrating', value: 'applicant credit rating' }, //	see appendix for list
        //{ id: 'dui', value: '0 or 1' },
        //{ id: 'homeowner', value: '0 or 1' },
        //{ id: 'military', value: '0 or 1' }, //	military affiliation

        { id: 'bundleinsurance', value: '0' }, //	wish to bundle auto/home insurance (0 or 1)
        { id: 'drivercount', value: '1' },
        { id: 'education', value: 'applicant education' }, //	see appendix for list
        { id: 'gender', value: 'M' }, //	 'M, F, or NB'
        { id: 'married', value: '0' },
        { id: 'occupation', value: 'applicant occupation' }, //	see appendix for list
        { id: 'recentincidentcount', value: '1' }, //# incidents in past three years
        { id: 'sr22', value: '0' },
        { id: 'suspension', value: '0' },
        { id: 'pubtreatmentversion', value: 'publisher treatment version' }, //	tracking variable that can be used for publisher controlled ab test
        { id: 'ni_session_ref', value: "referring url of user's session" }, //	pass the referring url for the visitor to your site
        { id: 'ni_quad_url', value: 'quad tracking url' }, //	pass the quad tracking url here
        { id: 'ni_lp_url', value: 'landing page of user' }, //	pass the original landing page url for the visitor to your site
        { id: 'ni_featured_buyer', value: '######' }, //	sh accountid of client to rank #1
        { id: 'ext_click_id', value: 'clickid from google/bing/fb/etc' },

        ////////////////////////////////

        /*
        { id: 'lp_campaign_id', value: '19414', type: 'hidden' },
        { id: 'lp_supplier_id', value: '44769', type: 'hidden' },
        { id: 'lp_key', value: 'lp6gue52pagled', type: 'hidden' },
        { id: 'lp_action', value: isDev() ? 'test' : '', type: 'hidden' },
        { id: 'lp_subid1', urlParameter: 'sub1', type: 'hidden', format: value => (isEmpty(value) ? '1' : value) }, // Not required
        { id: 'lp_subid2', urlParameter: 'sub2', type: 'hidden', format: value => (isEmpty(value) ? '2' : value) }, // Not required
        { id: 'lp_subid3', urlParameter: 'sub3', type: 'hidden' }, // Not required
        { id: 'lp_subid4', urlParameter: 'sub4', type: 'hidden' }, // Not required
        { id: 'lp_subid5', urlParameter: 'sub5', type: 'hidden' }, // Not required
        { id: 'device_type', value: 'MobileTablet,PC,Desktop', type: 'hidden' }, // TODO: Add support for options: MobileTablet, PC, Desktop
        //isDev() && { id: 'Test_Lead', value: 1, type: 'hidden' },
        { id: 'landing_page_url', value: hostAddress(), type: 'hidden' },
        //{ id: 'Format', value: 'JSON', type: 'hidden' },
        //{ id: 'Key', value: 'bd3d57ec78d5e34a56f4c2e721d0b07c1a985297514daf211c4e955b6520ea66', type: 'hidden' }, // Please provide your api key. This is located/created under Settings->API Keys
        //{ id: 'API_Action', value: 'submitLead', type: 'hidden' },
        //{ id: 'Mode', value: 'full', type: 'hidden' }, // ping | post | full (if full mode, then Lead_ID is not required)
        //{ id: 'TYPE', value: '32', type: 'hidden' },

        { id: 'transaction_ID', type: 'hidden', value: values => (!isClient ? undefined : (window as any)?.EF?.getAdvertiserTransactionId(1)) }, //urlParameter: '_ef_transaction_id' },
        //{ id: 'transaction_ID', value: '', type: 'hidden' },

        //{ id: 'Return_Best_Price', value: '0', type: 'hidden' },
        { id: 'user_agent', value: isClient && window?.navigator?.userAgent, type: 'hidden' },
        //{ id: 'SRC', value: 'Intrnl_Health_EX_SF', type: 'hidden' }, // Assigned by lead company
        //{ id: 'Age', type: 'hidden', value: values => getAge(values['DOB']) }, // Calculate this on submit, use the DOB and a decimal result
        //{ id: 'Tobacco_User', type: 'hidden', value: 'No' }, // Hard coded question/answer
        { id: 'ip_address', type: 'hidden' }, // Populated by useAutoIPAddressField
        // { id: 'Lead_ID', value: '', type: 'hidden' }, // This is received in response of sending a "ping" request, required in post mode.  Not required for 'full' Mode.
        //{ id: 'ttclid', type: 'hidden', urlParameter: 'ttclid' },
        //{ id: 'MSCLKID', type: 'hidden', urlParameter: 'MSCLKID' },
        { id: 'gclid', type: 'hidden', urlParameter: 'gclid' },
        { id: 'fbclid', type: 'hidden', urlParameter: 'fbclid' },
        //{ id: 'utm_content', type: 'hidden', urlParameter: 'utm_content' },
        //{ id: 'utm_medium', type: 'hidden', urlParameter: 'utm_medium' },
        //{ id: 'keyword', type: 'hidden', urlParameter: 'keyword' },
        //{ id: 'ad_id', type: 'hidden', urlParameter: 'ad_id' },
        //{ id: 'adset_id', type: 'hidden', urlParameter: 'adset_id' },
        //{ id: 'campaign_id', type: 'hidden', urlParameter: 'campaign_id' },
        //{ id: 'ad_name', type: 'hidden', urlParameter: 'ad_name' },
        //{ id: 'adset_name', type: 'hidden', urlParameter: 'adset_name' },
        //{ id: 'utm_source', type: 'hidden', urlParameter: 'utm_source' },
        //{ id: 'utm_campaign', type: 'hidden', urlParameter: 'utm_campaign' },
        //{ id: 'utm_term', type: 'hidden', urlParameter: 'utm_term' },
        //{ id: 'Url_String', value: isClient && window?.location?.href, type: 'hidden' },
        {
          id: 'jornaya_leadid',
          type: 'hidden',
          value: () => ((typeof window !== 'undefined' && window.document.querySelector('input[id="leadid_token"]')) as any)?.value
        },
        {
          id: 'trustedform_cert_url',
          value: () => ((typeof window !== 'undefined' && window.document.querySelector('input[id="xxTrustedFormCertUrl_0"]')) as any)?.value
        },
        */
        {
          id: 'currentlyinsured',
          required: true,
          showNext: false,
          xs: true,
          Field: (
            <MultiButtons
              onClick={next}
              items={[
                { label: 'Yes', value: '1' },
                { label: 'No', value: '0' }
              ].map(i => ({ ...i, sx: { height: 64 }, id: `currentlyinsured_${i.value}` }))}
            />
          )
        }
      ].filter(v => !isEmpty(v))
    },
    /*{
      id: 'current_insurer',
      label: 'Who is your current insurer?',
      showNext: false,
      Fields: [
        {
          id: 'carrier',
          required: true,
          xs: true,
          Field: <MultiButtons onClick={next} items={insuranceCarrierOptions.map(i => ({ ...i, sx: { height: 64 }, id: `carrier_${i.value}` }))} />
        }
      ].filter(v => !isEmpty(v))
    },  
    {
      id: 'age',
      label: 'What age group are you in?',
      showNext: false,
      Fields: [
        {
          id: 'age',
          required: true,
          xs: true,
          Field: <MultiButtons onClick={next} items={ageGroupOptions.map(i => ({ ...i, sx: { height: 64 }, id: `age_${i.value}` }))} />
        }
      ].filter(v => !isEmpty(v))
    },
    {
      id: 'creditrating',
      label: 'How is your credit?',
      showNext: false,
      Fields: [
        {
          id: 'creditrating',
          required: true,
          xs: true,
          Field: <MultiButtons onClick={next} items={creditRatingOptions.map(i => ({ ...i, sx: { height: 64 }, id: `creditrating_${i.value}` }))} />
        }
      ].filter(v => !isEmpty(v))
    },

    {
      id: 'recentincidentcount',
      label: 'How many accidents have you had in the last 3 years?',
      showNext: false,
      Fields: [
        {
          id: 'recentincidentcount',
          required: true,
          xs: true,
          Field: <MultiButtons onClick={next} items={recentIncidentOptions.map(i => ({ ...i, sx: { height: 64 }, id: `recentincidentcount_${i.value}` }))} />
        }
      ].filter(v => !isEmpty(v))
    },
    {
      id: 'dui',
      label: 'Have you had a DUI conviction in the last 3 years?',
      showNext: false,
      Fields: [
        {
          id: 'dui',
          required: true,
          xs: true,
          Field: (
            <MultiButtons
              onClick={next}
              items={[
                { label: 'Yes', value: '1' },
                { label: 'No', value: '0' }
              ].map(i => ({ ...i, sx: { height: 64 }, id: `dui_${i.value}` }))}
            />
          )
        }
      ]
    },*/
    {
      id: 'homeowner',
      label: 'Do you own your home?',
      showNext: false,
      Fields: [
        {
          id: 'homeowner',
          required: true,
          xs: true,
          Field: (
            <MultiButtons
              onClick={next}
              items={[
                { label: 'Yes', value: '1' },
                { label: 'No', value: '0' }
              ].map(i => ({ ...i, sx: { height: 64 }, id: `homeowner_${i.value}` }))}
            />
          )
        }
      ]
    },
    {
      id: 'vehiclecount',
      label: 'How many cars on policy?',
      showNext: false,
      Fields: [
        {
          id: 'vehiclecount',
          required: true,
          xs: true,
          Field: <MultiButtons onClick={next} items={vehicleCountOptions.map(i => ({ ...i, sx: { height: 64 }, id: `vehiclecount_${i.value}` }))} />
        }
      ].filter(v => !isEmpty(v))
    },

    {
      id: 'age',
      label: 'What age group are you in?',
      showNext: false,
      Fields: [
        {
          id: 'age',
          required: true,
          xs: true,
          Field: (
            <MultiButtons
              onClick={value => {
                console.log('Submitting with age...', { age: value });
                handleSubmit && handleSubmit({ age: value });
              }}
              items={ageGroupOptions.map(i => ({ ...i, sx: { height: 64 }, id: `age_${i.value}` }))}
            />
          )
        },
        {
          id: 'zipcode',
          label: 'Zip Code',
          placeholder: 'Zip',
          Field: GeoZipHidden,
          xs: 12,
          //required: true,
          hidden: true // must be here for the auto fill logic to work

          //validate: zip => (validateZipCode(zip) ? undefined : 'Invalid Zip')
        }
      ].filter(v => !isEmpty(v))
    }
    /*{
      id: 'date_of_birth',
      label: 'What is your date of birth?',
      canSkip: false,
      showSkip: false,
      Fields: [
        {
          id: 'date_of_birth',
          Field: <DateOfBirth autoFocus />,
          validate: value => (moment(value, 'YYYY-MM-DD', true).isValid() ? undefined : 'Please enter a valid date'),
          defaultValue: defaultDob,
          empty: value => isEmpty(value) || value === '//' || value === '--' || value === '---' || value === '///'
        },
        {
          id: 'zipcode',
          label: 'Zip Code',
          placeholder: 'Zip',
          Field: GeoZipHidden,
          xs: 12,
          //required: true,
          hidden: true // must be here for the auto fill logic to work

          //validate: zip => (validateZipCode(zip) ? undefined : 'Invalid Zip')
        }
      ]
    }*/
    /*{
      id: 'military',
      label: 'Have you served honorably in the U.S. military?',
      showNext: false,
      Fields: [
        {
          id: 'military',
          required: true,
          xs: true,
          Field: (
            <MultiButtons
              onClick={next}
              items={[
                { label: 'Yes', value: '1' },
                { label: 'No', value: '0' }
              ].map(i => ({ ...i, sx: { height: 64 }, id: `military_${i.value}` }))}
            />
          )
        }
      ]
    }*/
    /*
    {
      id: 'gender',
      label: "What's your gender?",
      showNext: false,
      Fields: [
        {
          id: 'gender',
          required: true,
          xs: true,
          Field: (
            <MultiButtons
              onClick={next}
              items={[
                { label: 'Male', value: 'Male' },
                { label: 'Female', value: 'Female' }
              ].map(i => ({ ...i, sx: { height: 64 }, id: `gender_${i.value}` }))}
            />
          )
        }
      ]
    },
    {
      id: 'date_of_birth',
      label: 'What is your date of birth?',
      canSkip: false,
      showSkip: false,
      Fields: [
        {
          id: 'date_of_birth',
          Field: <DateOfBirth autoFocus />,
          validate: value => (moment(value, 'YYYY-MM-DD', true).isValid() ? undefined : 'Please enter a valid date'),
          defaultValue: defaultDob,
          empty: value => isEmpty(value) || value === '//' || value === '--' || value === '---' || value === '///'
        }
      ]
    },
    {
      id: 'address',
      label: "What's your home address?",
      maxWidth: 'sm',
      Info: WhyWeAsk,
      required: true,
      canSkip: false,
      validate: async ({ Fields, values }) => {
        const response = await validateAddress({ addressLines: [`${values?.city}, ${values?.state} ${values?.zip_code}`] });
        const { result } = response;
        const verdict = result?.verdict;
        if (isEmpty(verdict) || verdict?.hasUnconfirmedComponents) {
          return {
            step: 'Invalid address!  Please ensure your City, State and Zip Code are correct.'
          };
        } else return {};
      },
      Fields: [
        {
          id: 'address',
          label: 'Street Address',
          placeholder: 'Enter your street address',
          Field: <Text autoFocus />,
          xs: 12,
          required: true,
          validate: v => (!(v ?? '').includes('@') ? validateVarChar(v, 100) : 'Please enter a valid street address!')
        },
        { id: 'city', label: 'City', placeholder: 'Enter city', Field: GeoCity, xs: 12, sm: 6, required: true, validate: v => validateVarChar(v, 100) },
        { id: 'state', label: 'State', Field: GeoState, xs: 6, sm: 3, required: true, validate: v => validateVarChar(v, 2) },
        {
          id: 'zip_code',
          label: 'Zip Code',
          placeholder: 'Zip',
          Field: GeoZip,
          xs: 6,
          sm: 3,
          required: true,
          validate: zip => (validateZipCode(zip) ? undefined : 'Invalid Zip')
        }
      ]
    },
    {
      id: 'contact',
      label: (
        <>
          Last step to receive your quotes!
          <br />
          What is your contact information?
        </>
      ),
      maxWidth: 'sm',
      Fields: [
        { id: 'first_name', required: true, Field: <Text label='First Name' autoFocus />, xs: 12, sm: 6, validate: v => validateVarChar(v, 100) },
        { id: 'last_name', required: true, label: 'Last Name', Field: Text, xs: 12, sm: 6, validate: v => validateVarChar(v, 100) },
        {
          id: 'email',
          label: 'Email',
          placeholder: 'Enter email',
          required: true,
          xs: true,
          sm: 8,
          Field: Text,
          validate: v => (validateEmail(v) ? validateVarChar(v, 100) : 'Please enter a valid email!')
        },
        {
          id: 'phone',
          format: value => formatPhoneNumber((value ?? '').replace(/[^0-9]/g, '')),
          Field: <PhoneNumber sx={{ width: undefined }} />, // Produces a 10 digit phone number with mask characters
          required: true,
          validate: value => (validatePhone(value) ? undefined : 'Invalid phone #'),
          xs: true
        },
        {
          id: 'tcpa_language',
          type: 'hidden',
          value: disclaimerText
        }
       
      ]
    }
      */
  ];
  /*return version === 1
    ? resultArray
    : arrayMoveNew(
        resultArray,
        resultArray.findIndex(q => q.id === 'pre_existing_conditions'),
        resultArray.findIndex(q => q.id === 'address')
      );
      */
  return resultArray;
};

export const getQuestion = ({ index, next = undefined, handleSubmit = undefined, version }) => getQuestions({ next, handleSubmit, version })[index];
export const questions = getQuestions();
export const getFieldIds = ({ index }) => questions[index]?.Fields.map(f => f.id);
export const getFieldSkipValues = ({ index }) => questions[index]?.Fields.map(f => ({ id: f.id, skipValue: f.skipValue ? evalFunc(f.skipValue, f) : '' }));
