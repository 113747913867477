import React from 'react';
import { Button, Container, Grid, Link } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Phone from '@mui/icons-material/Phone';
import Logo from 'components/application/Logo';
import useMediaQuery from '@mui/material/useMediaQuery';
import CallInfo from './CallInfo';
import { useCurrentPhone } from 'store/layout/store';
import { isPhoneTime } from 'utils/helpers';

export default function ApplicationBar() {
  const xs = useMediaQuery('(max-width:500px)');
  const currentPhone = useCurrentPhone();

  return (
    <>
      <AppBar
        position='fixed'
        color='inherit'
        elevation={1}
        sx={{
          backgroundColor: 'white',
          color: 'primary.main',
          px: 0,
          paddingRight: 0,
          minHeight: 69
        }}
      >
        <Container maxWidth='xl'>
          <Toolbar disableGutters>
            <Grid container alignItems='center' spacing={0} justifyContent='space-between'>
              <Grid item zeroMinWidth xs sx={{ ml: xs ? 0 : 1, cursor: 'pointer', color: 'primary.main', '&:hover': { color: 'primary.dark' } }}>
                <Logo />
              </Grid>
              {!isPhoneTime() ? (
                <Grid item></Grid>
              ) : (
                <Grid item>
                  {xs ? (
                    <Link id='phone-link-app-bar' href={`tel:${currentPhone.replace(/-|\s/g, '')}`} sx={{ textDecoration: 'none' }}>
                      <Button size='large' sx={{ px: 2, mr: 1, textTransform: 'none' }} variant='contained' color='primary'>
                        <Phone sx={{ fontSize: 20, mr: 0.5, color: 'white' }} />
                        Call us Today!
                      </Button>
                    </Link>
                  ) : (
                    <CallInfo id='phone-link-app-bar' />
                  )}
                </Grid>
              )}
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}
