import React from 'react';
import useChangeRoute from './useChangeRoute';

export default function useHandleChangeRoute() {
  const changeRoute = useChangeRoute();  
  return React.useCallback(
    (route, state = undefined) =>
      () =>
        changeRoute(route, state),

    [changeRoute]
  );
}
