import { updateState } from 'utils/helpers';

export interface State {
  [key: string]: DialogState | any;
}
export interface DialogState {
  open?: boolean;
  type: 'add' | 'edit' | 'view';
  errors?: any;
  showErrors?: boolean;
  submitting?: boolean;
  loading?: boolean;
  initialValues?: any;
  message?: string;
}

export const defaultDialogState = {
  open: false,
  type: 'add',
  errors: {},
  showErrors: false,
  submitting: false,
  initialValues: {}
};

const initialState = {};

export const updateDialog = (id, payload) => ({ type: 'UPDATE_DIALOG', id, payload });

export function reducer(state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_DIALOG':
      return updateState(state, action);
    case 'LOGOUT':
      return initialState;
    default:
      return state;
  }
}
