import React from 'react';
import { Box } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function ToolbarPlaceholder() {
  const xs = useMediaQuery('(max-width:500px)');
  return (
    <Box
      sx={{
        background: 'white',
        height: xs ? 69 : 69
      }}
    />
  );
}
