import { Box, Divider, Grid, Typography, useMediaQuery } from '@mui/material';
import { grey } from '@mui/material/colors';
import useHandleChangeRoute from 'utils/useHandleChangeRoute';
import Button from '@mui/material/Button';
import useHandleLink from 'utils/useHandleLink';
import pkg from 'package.json';
import { useRouter } from 'next/router';

const { siteName, siteLink, companyName } = pkg;

const links = [
  { label: 'Privacy Policy', href: 'PrivacyPolicy' },
  { label: 'Terms of Servce', href: 'TermsOfService' }
  //{ label: 'Do Not Sell My Info', href: 'DoNotSell' }
];

export const Footer = () => {
  const handleChangeRoute = useHandleChangeRoute();
  const sm = useMediaQuery('(max-width:750px)');
  const xs = useMediaQuery('(max-width:600px)');
  const handleLink = useHandleLink();
  const { pathname } = useRouter();

  const phoneNumber = pathname?.toLowerCase() === '/call' ? '(855) 608-1636' : '(877) 840-1693';

  return (
    <Box sx={{ backgroundColor: grey[50] }}>
      <Divider />
      <Box sx={{ backgroundColor: grey[50], pt: 0, pb: 1, px: 2 }}>
        <Grid container justifyContent='space-between' alignItems='center' spacing={1}>
          <Grid item xs>
            <Typography sx={{ color: 'text.secondary', fontSize: 10, textAlign: 'justify' }} component='div'>
              {`${companyName}`} is privately owned and operated. Fill out our easy form or call our phone number to get a No obligation quote. Our
              state-of-the-art technology and wide network of insurance providers will match you with a Licensed Insurance Agency that specializes in products
              in your zip code.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ px: 2, pt: 0 }}>
        <Grid container spacing={sm ? 1 : 2} justifyContent='space-between' alignItems='center'>
          <Grid item>
            <Grid container justifyContent='flex-start' alignItems='center' spacing={xs ? 0 : sm ? 1 : 4}>
              {links.map((link, index) => (
                <Grid item key={link.label} xs={xs ? 12 : undefined}>
                  <Button
                    id={link.href}
                    variant='text'
                    sx={{
                      p: 0.25,
                      color: 'text.secondary'
                    }}
                    onClick={handleChangeRoute(link.href)}
                  >
                    {link.label}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item sx={{ pb: 0.5 }} xs>
            <Grid container sx={{ textAlign: 'flex-end', color: 'text.secondary' }} justifyContent='flex-end' alignItems='center' spacing={0}>
              <Grid item>
                <Box sx={{ lineHeight: 1.35, fontSize: 12, textAlign: 'right' }}>© 2024</Box>
              </Grid>
              <Grid item sx={{ textAlign: 'flex-end' }}>
                <Box sx={{ lineHeight: 1.35, fontSize: 12, textAlign: 'right', ml: 1 }}>All Rights Reserved</Box>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{ lineHeight: 1.35, fontSize: 12, textDecoration: 'underline', color: 'blue', cursor: 'pointer', textAlign: 'right' }}
                onClick={handleLink(siteLink)}
                component='div'
              >
                {siteName}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
