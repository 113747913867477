import React from 'react';
import { useFormContext } from 'react-hook-form';
import getUrlParameter from 'utils/getUrlParameter';
import { isEmpty } from 'utils/helpers';
import { allFields } from '../Form/useFieldValues';

const useUrlParameterFields = () => {
  const urlParameterFieldValues = allFields
    .filter(f => !isEmpty(f.urlParameter))
    .map(f => ({ id: f.id, urlParameter: f.urlPramaeter, value: getUrlParameter(f.urlParameter) }));

  const formContext = useFormContext();
  const { setValue } = formContext;

  React.useEffect(() => {
    urlParameterFieldValues.forEach(({ id, value, urlParameter }) => {
      if (!isEmpty(value)) {
        console.log(`Setting urlPrameter (${urlParameter}), field ${id} to: ${value}`);
        setValue(id, value);
      }
    });
    // eslint-disable-next-line
  }, [setValue, JSON.stringify(urlParameterFieldValues)]);
};

export default useUrlParameterFields;
