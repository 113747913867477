import React from 'react';
import { Button } from '@mui/material';

const MultiButtons = ({ value = '', onChange = undefined, onClick = undefined, items = [] }) => {
  const handleClick = React.useCallback(
    value => () => {
      onChange({ target: { value } });
      onClick && onClick(value);
    },
    [onChange, onClick]
  );
  return (
    <>
      {items.map(({ label, id = undefined, xs = 12, value: buttonValue, sx, ...remaining }, i) => (
        <Button
          id={id}
          key={`${label}-${i}`}
          sx={{ fontSize: 24, mt: i == 0 ? 0 : 2, backgroundColor: value === buttonValue ? 'primary.dark' : undefined, ...sx }}
          onClick={handleClick(buttonValue)}
          variant='contained'
          fullWidth
          {...remaining}
        >
          {label}
        </Button>
      ))}
    </>
  );
};

export default MultiButtons;
