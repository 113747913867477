import React from 'react';

export default function useHandleLink() {
  return React.useCallback(
    link => () => {
      const win = window.open(link, '_blank');
      win && win.focus();
    },
    []
  );
}
