import Decimal from 'decimal.js-light';
import moment from 'moment';

export function hostAddress(append?: any) {
  return typeof window === 'undefined'
    ? ''
    : window.location.protocol +
        '//' +
        window.location.hostname +
        (window.location.port ? ':' + window.location.port : '') +
        (append !== undefined ? append : '');
}

export const publicUrl = (path: string) => {
  return typeof window !== 'undefined' ? hostAddress(path) : path;
};

export function isEmpty(str) {
  return !str || 0 === str.length;
}

export const evalFunc = (value, props = undefined as any) => (typeof value === 'function' ? value(props) : value);

export const isError = value => (value === undefined || value === null || value === '' ? false : true);

// Converts a string, number or boolean to boolean with default false
export const bool = x =>
  typeof x === 'boolean'
    ? x
    : typeof x === 'number'
    ? x === 1
      ? true
      : false
    : typeof x === 'string'
    ? x === '1'
      ? true
      : x.toLowerCase() === 'true'
      ? true
      : false
    : false;

export const isObject = obj => typeof obj === 'object';
export const isObjectWithKeys = obj => isObject(obj) && Object.keys(obj).length > 0;

export function uuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
    .replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    })
    .replace(/-/g, '');
}

export const checkEmpty = value => value === undefined || value === null || value === '' || (Array.isArray(value) && value.length === 0);
export const isDefined = value => value !== undefined && value !== null;

export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export function sortAscendingNumber(a = 0 as any, b = 0 as any) {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
}

export function sortDescendingNumber(a = 0 as any, b = 0 as any) {
  if (a > b) return -1;
  if (a < b) return 1;
  return 0;
}

export function sortAscendingDateParse(a = 0 as any, b = 0 as any) {
  return sortAscendingNumber(Date.parse(a), Date.parse(b));
}

export function sortDescendingDateParse(a = 0 as any, b = 0 as any) {
  return sortDescendingNumber(Date.parse(a), Date.parse(b));
}

export function sortAscendingDecimal(a = 0 as any, b = 0 as any) {
  if (new Decimal(withDefault(a, 0)).lessThan(new Decimal(withDefault(b, 0)))) return -1;
  if (new Decimal(withDefault(a, 0)).greaterThan(new Decimal(withDefault(b, 0)))) return 1;
  return 0;
}

export function sortDescendingDecimal(a = 0 as any, b = 0 as any) {
  if (new Decimal(withDefault(a, 0)).greaterThan(new Decimal(withDefault(b, 0)))) return -1;
  if (new Decimal(withDefault(a, 0)).lessThan(new Decimal(withDefault(b, 0)))) return 1;
  return 0;
}

export function sortAscendingToLower(a = '' as any, b = '' as any) {
  if ((a ?? '').toLowerCase() < (b ?? '').toLowerCase()) return -1;
  if ((a ?? '').toLowerCase() > (b ?? '').toLowerCase()) return 1;
  return 0;
}

export function sortDescendingToLower(a = '' as any, b = '' as any) {
  if ((a ?? '').toLowerCase() > (b ?? '').toLowerCase()) return -1;
  if ((a ?? '').toLowerCase() < (b ?? '').toLowerCase()) return 1;
  return 0;
}

export const sortRows = (rows, sort) => {
  const { column, direction } = sort;
  var sortFunction =
    column.sortType === 'decimal'
      ? direction === 'asc'
        ? sortAscendingDecimal
        : sortDescendingDecimal
      : column.sortType === 'dateParse'
      ? direction === 'asc'
        ? sortAscendingDateParse
        : sortDescendingDateParse
      : column.sortType === 'number'
      ? direction === 'asc'
        ? sortAscendingNumber
        : sortDescendingNumber
      : column.sortType === 'toLower'
      ? direction === 'asc'
        ? sortAscendingToLower
        : sortDescendingToLower
      : undefined;
  if (sortFunction) {
    console.log('Sorting!', { column, sort, direction, sortFunction });
    rows.sort((rowA, rowB) =>
      sortFunction(column.sortValue ? column.sortValue(rowA) : column.value(rowA), column.sortValue ? column.sortValue(rowB) : column.value(rowB))
    );
  }
  return rows;
};

export const stringifyEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b);

export const escapeRegex = searchtext => new RegExp(searchtext.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1'), 'gi'); // Ensures that all possible regex characters in searchtext are escaped before searching

export const isMatch = (obj, re) => {
  if (obj.getSearchValues) {
    return obj.getSearchValues().match(re);
  } else {
    for (var attrname in obj) {
      if (['_id', 'id', 'key', 'getValues', 'edit', 'action'].includes(attrname.toLowerCase())) continue;
      if (obj[attrname])
        if (isNaN(obj[attrname])) {
          //only search non numeric values
          try {
            if (obj[attrname].match(re)) return true;
          } catch (e) {
            continue;
          }
        } else {
          //it is a number
          try {
            if (obj[attrname].toString().match(re)) return true;
          } catch (e) {
            continue;
          }
        }
    }
  }
  return false;
};

export const isColumnExactMatch = (obj, cf) => {
  return obj[cf.column] != null && obj[cf.column] === cf.value ? true : false;
};

export function updateState(state, { payload, id }) {
  const data = evalFunc(payload, state[id]);
  var newState = { ...state };
  newState[id] = data;
  if (state && state[id]) {
    newState[id] = data;
  }
  return newState;
}

export function formatMoney(value) {
  return !isEmpty(value) ? value.toLocaleString('en-US') : value;
}

export function toMoney(value = 0 as any) {
  const fixedValue = new Decimal(withDefault(value, 0)).toFixed(2);
  const adornment = fixedValue.startsWith('-') ? '-$' : '$';
  return `${adornment}${formatMoney(fixedValue.replace('-', ''))}`;
}
export function centsToMoney(cents = 0) {
  return `$${formatMoney(new Decimal(cents).dividedBy(100).toFixed(2))}`;
}

export function formatNumberToCurrency(number = 0) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });
  return formatter.format(number);
}

export function formatCentsToCurrency(cents = 0) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });
  return formatter.format(Number(new Decimal(cents).dividedBy(100).toFixed(2)));
}

export const withDefault = (value, defaultValue) => (isEmpty(value) ? defaultValue : value);

export function yyyymmdd(date?: Date) {
  date = date ? date : new Date(); //If we don't pass a date in, then create it
  var mm = date.getMonth() + 1; // getMonth() is zero-based
  var dd = date.getDate();
  return [date.getFullYear(), (mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd].join('-');
}

export function mmddyyyy(date) {
  var mm = date.getMonth() + 1; // getMonth() is zero-based
  var dd = date.getDate();
  return [(mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd, date.getFullYear()].join('/');
}

export const isDev = () => process.env.NODE_ENV === 'development';
export const isProduction = () => process?.env?.NODE_ENV?.toLowerCase() === 'production';

export const applyPagination = (rows: any[], page: number, rowsPerPage: number): any[] => rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

export function padLeadingZeros(num, totalLength) {
  return String(num).padStart(totalLength, '0');
}

export function getAge(dob) {
  if (moment(dob, 'YYYY-MM-DD', true).isValid()) {
    return new Decimal(moment().diff(dob, 'years', true)).toDecimalPlaces(2).toNumber();
  } else {
    return undefined;
  }
}

export function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const isClient = typeof window !== 'undefined';

export function arrayMoveNew(oldArray, fromIndex, toIndex) {
  const newArray = [...oldArray];
  var element = newArray[fromIndex];
  newArray.splice(fromIndex, 1);
  newArray.splice(toIndex, 0, element);
  return newArray;
}

export function arrayMove(arr, fromIndex, toIndex) {
  var element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
}

export const isPhoneTime = () => {
  const hour = new Date().getHours();
  // is hour between 8AM - 7PM local time
  const startHour = 8;
  const endHour = 19;
  return hour >= startHour && hour <= endHour;
};

export const isMediaAlphaTime = () => {
  const hour = new Date().getHours();
  // Show media alpha results from 8 PM to 8 AM
  const startHour = 8;
  const endHour = 20;
  if (hour >= endHour) {
    return true;
  } else if (hour < startHour) {
    return true;
  } else {
    return false;
  }
};

export const capitalizeString = (value = '') => {
  const words = value.split(' ');

  return words
    .map(word => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(' ');
};

export const formatPhoneNumber = value => {
  if (value?.startsWith('1')) {
    return value.substring(1);
  } else {
    return value;
  }
};
