import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { AppState } from 'store/store';

export interface State {
  open?: boolean;
  variant?: 'success' | 'error' | 'warning' | 'info';
  message?: string;
}
const initialState = {};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SNACKBAR':
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

const updateSnackbar = (payload: State) => ({ type: 'SNACKBAR', payload });
export const useSnackbar = (): [State, (SnackbarProps) => void] => {
  const dispatch = useDispatch();
  const state: State = useSelector((state: AppState) => state.snackbar, shallowEqual);
  const setState = React.useCallback((payload: State) => dispatch(updateSnackbar(payload)), [dispatch]);
  return [state, setState];
};
