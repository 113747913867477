import { useFormContext } from 'react-hook-form';
import { evalFunc } from 'utils/helpers';
import { questions } from './questions';

export const allFields = questions.reduce(
  (t, { Fields = [] as any }) =>
    (t = [
      ...t,
      ...Fields.map(({ id, type, formLabel, value, defaultValue, format, urlParameter, exclude }) => ({
        id,
        type,
        formLabel,
        value,
        defaultValue,
        format,
        urlParameter,
        exclude
      }))
    ]),
  []
);

export const getFieldValues = getValues => {
  const formValues = getValues();
  return allFields.reduce((t, c) => ({ ...t, [c.id]: evalFunc(c.value ?? formValues[c.id], formValues) }), {});
};

export const formatFieldValues = values => {
  const newValues = { ...values };
  allFields
    .filter(f => f.format)
    .forEach(({ id, format }) => {
      newValues[id] = format(newValues[id], newValues);
      newValues[id] !== values[id] && console.log(`Formatted field ${id} from ${values[id]} to ${newValues[id]}`);
    });
  return newValues;
};

const useFieldValues = () => {
  const formContext = useFormContext();
  const { getValues } = formContext;
  return getFieldValues(getValues);
};

export default useFieldValues;
