import * as React from 'react';
import { useRouter } from 'next/router';
import { publicUrl } from './helpers';
import { useRouteState } from 'store/layout/store';
import { useScrollElement } from 'components/layout/ScrollElementProvider';

function paramsToObject(entries) {
  const result = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
}

const urlParamsToExclude = [];
const urlStartsWithParamsToExclude = ['CallNowDialog'];

export function setScrollTop() {
  setTimeout(() => {
    const appContentElement = (window as any)?.document?.getElementById && (window as any)?.document.getElementById('app-content');
    try {
      (appContentElement as any)?.scrollTo(0, 0);
    } catch {}
  }, 0);
}

export const filterNewRouteParams = ({ route, params, includeParams = [] }) => {
  const parts = publicUrl(route).split('?');
  const pathname = parts[0];
  var query = params.toString(); // original params
  if (parts.length > 0) {
    var originalQuery = parts[1];
    const urlParams = new URLSearchParams(originalQuery);
    const newParams = paramsToObject(urlParams.entries()); //{abc:"foo",def:"[asf]",xyz:"5"}
    const oldParams = paramsToObject(params.entries()); //{abc:"foo",def:"[asf]",xyz:"5"}

    var mergedParams = {};
    Object.keys(oldParams)
      .filter(
        k =>
          !urlParamsToExclude.filter(p => !includeParams.find(ip => ip === p)).includes(k) &&
          !urlStartsWithParamsToExclude.filter(p => !includeParams.find(ip => ip.startsWith(p))).includes(k)
      )
      .forEach(k => (mergedParams[k] = oldParams[k])); // Include all old parameters
    Object.keys(newParams).forEach(k => (mergedParams[k] = newParams[k])); // Any new url parameters overwrite the old parameters with the same name

    query = Object.keys(mergedParams)
      .map(k => `${k}=${mergedParams[k]}`)
      .join('&');

    console.log({ oldParams, newParams, mergedParams, query });
  }

  return { pathname, query };
};

export default function useChangeRoute() {
  const router = useRouter();
  const params = new URLSearchParams(router.query as any);
  const [, setRouteState] = useRouteState();
  const scrollElement = useScrollElement();
  return React.useCallback(
    (route, state = undefined, includeParams = []) => {
      const { pathname, query } = filterNewRouteParams({ route, params, includeParams });
      console.log('Changing route', { pathname, query, route, includeParams });
      router.push({ pathname, query });

      //router.push({ pathname: publicUrl(route), query: params.toString() });
      setRouteState(state);
      setTimeout(() => {
        scrollElement?.scrollTo({ top: 0, behavior: 'smooth' });
      }, 150);
    },
    // eslint-disable-next-line
    [JSON.stringify(params), router, scrollElement]
  );
}
