import React from 'react';
import { Grid, Link, Paper, Typography } from '@mui/material';
import Phone from '@mui/icons-material/Phone';
import pkg from 'package.json';
import useMediaQuery from '@mui/material/useMediaQuery';
import { green } from '@mui/material/colors';
import { useCurrentPhone } from 'store/layout/store';
import Image from 'next/image';

const { currentPhoneDetails } = pkg;

export default function CallInfo({ variant = 'normal', id }) {
  const xs = useMediaQuery('(max-width:500px)');
  const currentPhone = useCurrentPhone();
  return (
    <Link id={id} href={`tel:${currentPhone.replace(/-|\s/g, '')}`} sx={{ textDecoration: 'none' }}>
      <Paper
        elevation={0}
        sx={{
          borderRadius: 1,
          px: xs ? 0 : 0.5,
          pr: xs ? 1 : 2,
          py: 1,
          color: 'primary.dark',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'grey.100'
          }
        }}
      >
        <Grid container justifyContent='flex-end' alignItems='flex-start' spacing={xs ? 0 : 0.5}>
          <Grid item sx={{ mt: -1.25 }}>
            <Image src='/Phone-400x400-white.gif' height={64} width={64} alt='phone' />
          </Grid>
          <Grid item xs>
            <Typography sx={{ color: 'text.primary', textAlign: 'center', fontSize: 20, fontWeight: 500 }}>{currentPhone}</Typography>
            <Typography textAlign='center' noWrap component='div' sx={{ fontSize: 9, fontWeight: 500, color: 'text.primary' }}>
              <Grid container alignItems='center' spacing={0.5}>
                <Grid item>
                  <Image src='/pulse_120x120.gif' height={12} width={12} alt='pulse' />
                </Grid>
                <Grid item>Speak with a Licensed Insurance Agent.</Grid>
              </Grid>
            </Typography>
            {variant !== 'small' && (
              <Typography textAlign='center' noWrap component='div' sx={{ color: 'text.primary', fontSize: 10, fontWeight: 500 }}>
                {currentPhoneDetails}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Link>
  );
}
