import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
//F44336
// Create a theme instance.
const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#26739f'
    },
    secondary: {
      main: '#ee6c07'
    },
    error: {
      main: red[600]
    }
  },
  layout: {
    progressSize: 128,
    toolbarheight: 93
  }
} as any);

export default theme;
