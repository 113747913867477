import * as layout from './layout/store';
import * as dialog from './dialog/store';
import * as snackbar from './snackbar/store';
import localforage from 'localforage';
import pkg from '../package.json';
import { persistReducer } from 'redux-persist';
import { createStore, combineReducers, Store } from 'redux';

export const isPersistorEnabled = typeof window !== 'undefined' && pkg.enablePersistor;

isPersistorEnabled &&
  localforage &&
  localforage.config &&
  localforage.config({
    driver: localforage.INDEXEDDB, // Force WebSQL; same as using setDriver()
    name: `${pkg.name}-local-storage`,
    version: 1.0,
    size: 4980736, // Size of database, in bytes. WebSQL-only for now.
    storeName: 'keyvaluepairs', // Should be alphanumeric, with underscores.
    description: `${pkg.name} application database`
  });

export const persistConfig = {
  key: 'app',
  storage: localforage
};

const reducers = combineReducers({
  layout: layout.reducer,
  dialog: dialog.reducer,
  snackbar: snackbar.reducer
}) as any;

const storeReducers = isPersistorEnabled ? persistReducer(persistConfig, reducers) : reducers;

export type AppState = {
  layout: layout.State;
  dialog: dialog.State;
  snackbar: snackbar.State;
};

const store = createStore(storeReducers) as Store<AppState>;

export default store;
