import { isClient, isEmpty } from './helpers';

export default function getUrlParameter(paramName) {
  if (isClient) {
    var sPageURL = window.location.search.substring(1),
      sURLVariables = sPageURL.split('&'),
      sParameterName,
      i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');

      if (sParameterName[0] === paramName) {
        return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
      }
    }
    return undefined;
  } else {
    return undefined;
  }
}

export function getUrlParameterWithDefault(paramName, defaultValue) {
  const up = getUrlParameter(paramName);
  return isEmpty(up) ? defaultValue : up;
}
