import React from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import useHandleChangeRoute from '../../utils/useHandleChangeRoute';
import Image from 'next/image';

var scale = 4.1252;

export default function Logo({ sx = {} } = {}) {
  const handleChangeRoute = useHandleChangeRoute();
  const { layout } = useTheme() as any;
  const sm = useMediaQuery('(max-width:560px)');
  const xs = useMediaQuery('(max-width:460px)');
  const xs2 = useMediaQuery('(max-width:360px)');
  const height = xs2 ? 25 : xs ? 40 : sm ? 50 : 60;
  return (
    <Box sx={{ pt: xs || xs2 ? 1.5 : sm ? 1 : 0.5 }} onClick={handleChangeRoute('/')}>
      <Image src='/logo2.webp' width={height * scale} height={height} alt='logo' />
    </Box>
  );
}
