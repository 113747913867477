import axios from 'axios';
import React from 'react';
import { useSelector } from 'react-redux';
import { useUpdateLayout } from 'store/layout/store';
import { AppState } from 'store/store';
import { publicUrl } from './helpers';

export const defaultGeoState = {
  IPv4: undefined,
  city: undefined,
  country: undefined,
  country_name: undefined,
  latitude: undefined,
  longitude: undefined,
  postal: undefined,
  state: undefined,
  loading: false
};

export default function useGeoData({ trigger }) {
  const state = useSelector((s: AppState) => s.layout.geoData);
  const updateLayout = useUpdateLayout();
  const getData = async () => {
    console.log('Requesting geo data...');
    const res = await axios.get(publicUrl('/api/geo'));
    console.log('Received geo data...', res.data);
    updateLayout({ geoData: { ...res.data, loading: false } });
  };

  React.useEffect(() => {
    trigger && getData();
  }, [trigger]);

  return state;
}

export function useIsZipSet() {
  const updateLayout = useUpdateLayout();
  const isZipSet = React.useCallback(
    ({ isZipSet = true }) => {
      updateLayout({ isZipSet });
    },
    [updateLayout]
  );
  return isZipSet;
}
